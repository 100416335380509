<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-03 14:21:23
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 17:11:14
-->
<template>
  <div class="install-evaluation">
    <div class="personal-center-title">
      评价记录
    </div>
    <div class="install-body">
      <div class="install-body-header">
        <div class="header-left">
          <span class="title">已评价订单</span>
          <span class="digital">{{ total }}条</span>
        </div>
        <div class="header-right">
          <el-input
            placeholder="搜索订单"
            v-model="search"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="iconfont icon-sousu"
              @click="handleSearch"
            ></el-button>
          </el-input>
          <el-button icon="iconfont icon-shaixuan1"></el-button>
        </div>
      </div>
      <div class="install-body-list">
        <table v-show="tableData.length > 0">
          <tbody
            class="list-line-item"
            v-for="(item, i) in tableData"
            :key="i"

          >
            <tr class="sep-row">
              <td colspan="2"></td>
            </tr>
            <tr>
              <td colspan="2" class="line-item-colspan">
                <span class="order-number"> 订单号：{{ item.orderNum }} </span>
                <span class="time"> 评价时间：{{ item.evaluationTime }} </span>
              </td>
            </tr>
            <tr>
              <td class="line-item item-830">
                <div class="evaluation">
                  {{ item.evaluation }}
                </div>
                <el-rate
                  :value="item.installationEvaluation"
                  disabled
                  :colors="['rgba(250, 200, 29, 1)', 'rgba(250, 200, 29, 1)', 'rgba(250, 200, 29, 1)']"
                >
                </el-rate>
              </td>
              <td class="line-item item-130">
                <el-button type="primary" @click="handleDetails(item.id)">查看详情</el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-show="tableData.length <= 0">
          <tbody class="list-line-item">
            <tr class="empty-row">
              <td colspan="4">
                暂无数据
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <u-pagination v-if="total > 0" :total="total" @change="changePagination"></u-pagination>
    </div>
  </div>
</template>

<script>
import UPagination from "@/components/UPagination";

export default {
  components: {
    UPagination
  },
  data() {
    return {
      search: "",
      orderId: null,
      params: {
        num: 10,
        page: 1
      },
      total: 0,
      tableData: []
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getInstallationOrder();
  },
  methods: {
    getInstallationOrder() {
      let params = {
        search: this.search,
        userId: this.userId,
        ...this.params
      };
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getOrderEvaluation(params)
        .then(res => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    initInstallationOrder() {
      this.params.page = 1;
      this.getInstallationOrder();
    },

    changePagination(page, pageSize) {
      this.params = {
        num: pageSize,
        page: page
      };
      this.getInstallationOrder();
    },
    handleSearch() {
      this.initInstallationOrder();
    },

    handleDetails(id) {
      this.$router.push({
        path: "/personal-center/install/details",
        query: { type: 6, oid: id }
      });
      sessionStorage.setItem('isReoder', 0)
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
